import React from 'react'
import { Route, Switch } from '@deal/router'
import { FullPageLoader } from '@deal/components'
import { useIdentityContext } from '#src/app/context/Identity'
import NotFound from '#src/app/components/Errors/NotFound'
import * as Routes from './routes'

const Router: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { operator } = useIdentityContext()
  if (operator) {
    return (
      <Switch>
        <Route path="/admin" component={Routes.Admin} />
        <Route path="/assistants" component={Routes.Assistants} />
        <Route path="/attributes" component={Routes.Attributes} />
        <Route path="/brands" component={Routes.Brands} />
        <Route path="/call-center" component={Routes.CallCenter} />
        <Route path="/categories" component={Routes.Categories} />
        <Route path="/virtual-categories-tree" component={Routes.VirtualCategoriesTree} />
        <Route path="/consumers" component={Routes.Consumers} />
        <Route path="/content" component={Routes.Content} />
        <Route path="/devtools" component={Routes.DevTools} />
        <Route path="/experts" component={Routes.Experts} />
        <Route path="/llm" component={Routes.LargeLanguageModel} />
        <Route path="/experiments" component={Routes.Experiments} />
        <Route path="/inventory" component={Routes.Inventory} />
        <Route path="/leads" component={Routes.Leads} />
        <Route path="/marketing" component={Routes.Marketing} />
        <Route path="/media-assets" component={Routes.MediaAssets} />
        <Route path="/merchants" component={Routes.Merchants} />
        <Route path="/orders" component={Routes.Orders} />
        <Route path="/partners" component={Routes.Partners} />
        <Route path="/payroll" component={Routes.Payroll} />
        <Route path="/scheduling" component={Routes.Scheduling} />
        <Route path="/sellables" component={Routes.Sellables} />
        <Route path="/templates" component={Routes.Templates} />
        <Route path="/triggers" component={Routes.Triggers} />
        <Route path="/marketing" component={Routes.Marketing} />
        <Route path="/navigation" component={Routes.Navigation} />
        <Route path="/paths" component={Routes.Path} />
        <Route path="/product-catalog" component={Routes.ProductCatalog} />
        <Route path="/email-templates" component={Routes.EmailTemplate} />
        <Route path="/expert-lead-campaign" component={Routes.ExpertLeadCampaign} />
        <Route path="/content-selection-rules" component={Routes.ContentSelectionRules} />
        <Route path="/content-selection" component={Routes.ContentSelection} />
        <Route path="/ads-rule" component={Routes.AdsRule} />
        <Route path="/sellable-search-rules" component={Routes.SellableSearchRules} />
        <Route path="/purchase-orders" component={Routes.PurchaseOrder} />
        <Route path="/page-templates" component={Routes.PageTemplates} />
        <Route path="/standardization" component={Routes.Standardization} />
        <Route path="/affiliates" component={Routes.Affiliate} />
        <Route
          path="/affiliate-campaigns-management"
          component={Routes.AffiliateCampaignsManagement}
        />
        <Route path="/category-target-margins" component={Routes.CategoryTargetMargins} />
        <Route path="/template-data-sources" component={Routes.TemplateDataSources} />
        <Route path="/external-conversions" component={Routes.ExternalConversions} />
        <Route path="/general-ledger" component={Routes.GeneralLedger} />
        <Route path="/content/videos" component={Routes.VideoContentManagement} />
        <Route path="/content/curated-lists" component={Routes.CuratedListsPage} />
        <Route path="/" exact component={Routes.Home} />
        <Route component={NotFound} />
      </Switch>
    )
  } else {
    // this can happen during impersonating on/off when the operator is being updated
    return <FullPageLoader />
  }
}

export default Router
