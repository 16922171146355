import { useEffect, useState } from 'react'
import { Permission } from '#src/generated/types'
import { hasPermission } from '#src/app/utils/permission'
import { navigationMenuItemsStore } from '#src/app/stores'
import { useIdentityContext } from '../context/Identity'
import { useOpenVipTicketsCount } from './useOpenVipTicketsCount/index'
import { useConversationsCount } from './useConversationsCount'

export interface MenuItem {
  label: string
  to: string
  badgingCount?: number
  hasAccess: boolean
  showVipBadge?: boolean
}

export interface Menu {
  label: string
  emoji: string
  badgingCount?: number
  menuItems?: MenuItem[]
  to?: string
  showVipBadge?: boolean
}

const useAllNavigationMenus = (): Menu[] => {
  const { operator, permissions } = useIdentityContext()
  const conversationsCount = useConversationsCount()
  const openVipTicketsCount = useOpenVipTicketsCount()

  // search expert should look for active expert by default. the url param is pretty insane at this point.
  // we need to fix faceted search parameter serialization.
  const defaultExpertSearchParam =
    'role=%7B"name"%3A"role"%2C"bucketValues"%3A%5B"expert"%5D%7D&state=%7B"name"%3A"state"%2C"bucketValues"%3A%5B"ACTIVE"%5D%7D'

  if (!operator || !permissions) {
    return []
  }

  return [
    {
      label: 'Agents',
      emoji: '🧑‍💼',
      badgingCount: conversationsCount,
      menuItems: [
        {
          label: 'Dashboard',
          to: `/orders/tickets/dashboard?assignedTo=${operator.id}&includeUnassigned=false`,
          hasAccess: hasPermission(operator, Permission.TICKET_SEARCH)
        },
        {
          label: 'Call Center',
          to: `/call-center`,
          hasAccess: hasPermission(operator, Permission.INAPP_PHONE_CALL)
        },
        {
          label: 'Message Center',
          badgingCount: conversationsCount,
          to: '/orders/messages',
          hasAccess: hasPermission(operator, Permission.SUPPORT_CONVERSATION_SEARCH)
        }
      ]
    },
    {
      label: 'Orders',
      emoji: '🧾',
      badgingCount: openVipTicketsCount,
      showVipBadge: true,
      menuItems: [
        {
          label: 'Orders',
          to: '/orders/list/product',
          hasAccess: hasPermission(operator, Permission.ORDER_READ)
        },
        {
          label: 'My Tasks',
          to: `/orders/fulfillmentTickets?assignedTo=${operator.id}`,
          badgingCount: openVipTicketsCount,
          showVipBadge: true,
          hasAccess: hasPermission(operator, Permission.TICKET_SEARCH)
        },
        {
          label: 'Fullfillment Channels',
          to: '/orders/fulfillmentChannels',
          hasAccess: hasPermission(operator, Permission.FULFILLMENT_CHANNELS_SEARCH)
        },
        {
          label: 'Shipment Pricing Models',
          to: '/orders/shipmentPricingModels',
          hasAccess: hasPermission(operator, Permission.SHIPMENT_PRICING_MODELS_SEARCH)
        },
        {
          label: 'Merchants',
          to: '/merchants',
          hasAccess: hasPermission(operator, Permission.MERCHANT_SEARCH)
        },
        {
          label: 'Flxpoint Invoices',
          to: '/orders/flxpoint-invoices',
          hasAccess: hasPermission(operator, Permission.MERCHANT_SEARCH)
        },
        {
          label: 'Connect Partner Transactions',
          to: '/orders/connectPartnerTransactions',
          hasAccess: hasPermission(operator, Permission.CONNECT_PARTNER_TRANSACTION_READ)
        },
        {
          label: 'Publisher Payouts',
          to: '/orders/publisherPayouts',
          hasAccess: hasPermission(operator, Permission.PUBLISHER_PAYOUT_READ)
        },
        {
          label: 'Warehouse Shipping Cost Upload',
          to: '/orders/shippingCost/upload',
          hasAccess: hasPermission(operator, Permission.INVENTORY_UPLOAD)
        },
        {
          label: 'Product Cost Correction Upload',
          to: '/orders/productCostCorrection/upload',
          hasAccess: operator.isAdmin
        },
        {
          label: 'Fulfillment Cost Verification Upload',
          to: '/orders/fulfillmentCostVerification/upload',
          hasAccess: hasPermission(operator, Permission.ORDER_FULFILL)
        },
        {
          label: 'Connect Partner Transactions Upload',
          to: '/orders/connectPartners/upload',
          hasAccess: hasPermission(operator, Permission.CONNECT_PARTNER_TRANSACTION_UPLOAD)
        },
        {
          label: 'Connect Partner Refunds Upload',
          to: '/orders/connectPartnerRefunds/upload',
          hasAccess: hasPermission(operator, Permission.CONNECT_PARTNER_TRANSACTION_UPLOAD)
        },
        {
          label: 'Internal Orders',
          to: '/orders/internalOrders',
          hasAccess: hasPermission(operator, Permission.INTERNAL_ORDER_READ)
        },
        {
          label: 'Customer Service FAQ',
          to: '/orders/customerServiceFaq',
          hasAccess: hasPermission(operator, Permission.CUSTOMER_SERVICE_FAQ_MANAGE)
        }
      ]
    },
    {
      label: 'Partners',
      emoji: '🤝',
      menuItems: [
        {
          label: 'Partners Management',
          to: '/partners/accounts',
          hasAccess: hasPermission(operator, Permission.PARTNER_ACCOUNT_MANAGE)
        },
        {
          label: 'Partner Shipment Methods',
          to: '/partners/shipment-methods',
          hasAccess: hasPermission(operator, Permission.FULFILLMENT_SHIPPING_METHOD_MANAGE)
        },
        {
          label: 'Partner Orders Consolidation View',
          to: `/partners/partner-orders`,
          hasAccess: [
            Permission.ORDER_FULFILL,
            Permission.PARTNER_ORDER_READ,
            Permission.PARTNER_FULFILLMENT_READ,
            Permission.PARTNER_ORDER_INVOICE_READ,
            Permission.PARTNER_ORDER_INVOICE_SEARCH
          ].every(p => hasPermission(operator, p))
        },
        {
          label: 'Partner Inventory',
          to: `/partners/partner-inventory`,
          hasAccess: hasPermission(operator, Permission.PARTNER_INVENTORY_READ)
        },
        {
          label: 'Partner Invoices',
          to: `/partners/partner-invoices`,
          hasAccess: hasPermission(operator, Permission.PARTNER_ORDER_INVOICE_READ)
        },
        {
          label: 'Partner Credit Memos',
          to: `/partners/partner-credit-memos`,
          hasAccess: hasPermission(operator, Permission.PARTNER_ORDER_INVOICE_READ)
        },
        {
          label: 'Partner Contacts',
          to: `/partners/partner-contacts`,
          hasAccess: hasPermission(operator, Permission.PARTNER_ACCOUNT_MANAGE)
        },
        {
          label: 'Partner Performance',
          to: `/partners/partner-performance`,
          hasAccess: hasPermission(operator, Permission.PARTNER_ORDER_READ)
        },
        {
          label: 'Demo Requests Consolidation View',
          to: `/partners/demo-requests`,
          hasAccess: hasPermission(operator, Permission.DEMO_REQUEST_READ)
        }
      ]
    },
    {
      label: 'Consumers',
      emoji: '🛍',
      menuItems: [
        {
          label: 'User Search',
          to: '/consumers/search',
          hasAccess: hasPermission(operator, Permission.USER_SEARCH)
        },
        {
          label: 'Lead Search',
          to: '/leads/search',
          hasAccess: hasPermission(operator, Permission.LEAD_SEARCH)
        }
      ]
    },
    {
      label: 'Experts',
      emoji: '🥇',
      menuItems: [
        {
          label: 'Expert Search',
          to: `/experts/search?${defaultExpertSearchParam}`,
          hasAccess: hasPermission(operator, Permission.BUSINESS_USER_SEARCH)
        },
        {
          label: 'Applicant Search',
          to: '/experts/applications/search',
          hasAccess: hasPermission(operator, Permission.EXPERT_APPLICATION_SEARCH)
        },
        {
          label: 'Hiring Pipeline',
          to: '/experts/onboarding/hiring',
          hasAccess: hasPermission(operator, Permission.HIRING_FLOW_READ)
        },
        {
          label: 'Incentive Plans',
          to: '/payroll/pay-structures',
          hasAccess: hasPermission(operator, Permission.PAY_STRUCTURE_READ)
        },
        {
          label: 'Payouts',
          to: '/payroll/payouts',
          hasAccess: hasPermission(operator, Permission.PAYOUT_READ)
        },
        {
          label: 'Payable Entries',
          to: '/payroll/payables',
          hasAccess: hasPermission(operator, Permission.PAYABLE_ENTRY_READ)
        },
        {
          label: 'Billable Hours',
          to: '/payroll/billable-hours',
          hasAccess: hasPermission(operator, Permission.PAYABLE_ENTRY_READ)
        },
        {
          label: 'Scheduling',
          to: '/scheduling',
          hasAccess: hasPermission(operator, Permission.SHIFT_ASSIGNMENT_READ)
        },
        {
          label: 'Rank Advancement Rules',
          to: '/experts/advancement-rules',
          hasAccess: hasPermission(operator, Permission.EXPERT_CATEGORY_SETTING_READ)
        },
        {
          label: 'Expert Category Setting',
          to: '/experts/category-settings',
          hasAccess: hasPermission(operator, Permission.EXPERT_CATEGORY_SETTING_READ)
        },
        {
          label: 'Expert Account Requests',
          to: '/experts/account-change-requests',
          hasAccess: hasPermission(operator, Permission.EXPERT_ACCOUNT_CHANGE_REQUEST_READ)
        },
        {
          label: 'Lead RPAL Exclusion Requests',
          to: '/experts/lead-rpal-exclusion-override-requests',
          hasAccess: hasPermission(operator, Permission.LEAD_RPAL_OVERRIDE_REQUEST_APPROVE)
        },
        {
          label: 'FrontEnd Task',
          to: `/experts/discountcash/upload`,
          hasAccess: hasPermission(operator, Permission.DISCOUNT_CASH_UPLOAD)
        },
        {
          label: 'Suggested Actions',
          to: `/experts/suggested-actions`,
          hasAccess: hasPermission(operator, Permission.SUGGESTED_ACTION_SEARCH)
        },
        {
          label: 'Training Scenarios',
          to: `/experts/training-scenarios`,
          hasAccess: hasPermission(operator, Permission.LEAD_TRAINING_SCENARIO_READ)
        },
        {
          label: 'Suggested Messages',
          to: `/experts/suggested-messages`,
          hasAccess: hasPermission(operator, Permission.SUGGESTED_ACTION_SEARCH)
        },
        {
          label: 'Expert Bulk Update',
          to: `/experts/expert-bulk-update-tasks`,
          hasAccess: hasPermission(operator, Permission.EXPERT_BULK_UPDATE_TASK_READ)
        },
        {
          label: 'LLM Message Search',
          to: `/experts/message-search`,
          hasAccess: hasPermission(operator, Permission.LEAD_READ)
        }
      ]
    },
    {
      label: 'Assistants',
      emoji: '🧑‍💻',
      menuItems: [
        {
          label: 'Dashboard',
          to: '/assistants/dashboard',
          hasAccess: permissions.isLeadSupport
        }
      ]
    },
    {
      label: 'Merchandising',
      emoji: '🗂',
      menuItems: [
        {
          label: 'Product Catalog',
          to: '/product-catalog',
          hasAccess: hasPermission(operator, Permission.PRODUCT_CATALOG_SEARCH)
        },
        {
          label: 'Sellables Search',
          to: '/sellables/search',
          hasAccess: hasPermission(operator, Permission.SELLABLE_SEARCH_REQUEST)
        },
        {
          label: 'Raw Sellable Sources',
          to: '/sellables/raw-sellable-sources',
          hasAccess: hasPermission(operator, Permission.SELLABLE_INGESTION_MANAGE)
        },
        {
          label: 'Standardization Rules',
          to: '/inventory/standardization',
          hasAccess: hasPermission(operator, Permission.SELLABLE_INGESTION_MANAGE)
        },
        {
          label: 'Standardization Rule Engine V2 (WIP)',
          to: '/standardization',
          hasAccess: hasPermission(operator, Permission.SELLABLE_INGESTION_MANAGE)
        },
        {
          label: 'Sellable Standardization Results',
          to: '/product-catalog/standardization',
          hasAccess:
            hasPermission(operator, Permission.SELLABLE_INGESTION_MANAGE) &&
            hasPermission(operator, Permission.PRODUCT_CATALOG_SEARCH)
        },
        {
          label: 'Bundles',
          to: '/sellables/bundles',
          hasAccess: hasPermission(operator, Permission.PRODUCT_BUNDLE_SEARCH)
        },
        {
          label: 'Brands',
          to: '/brands',
          hasAccess: hasPermission(operator, Permission.PRODUCT_CATALOG_SEARCH)
        },
        {
          label: 'Upload Brands',
          to: '/brands/upload',
          hasAccess: hasPermission(operator, Permission.PRODUCT_CATALOG_IMPORT)
        },
        {
          label: 'Upload Inventory',
          to: '/inventory/upload',
          hasAccess: hasPermission(operator, Permission.INVENTORY_UPLOAD)
        },
        // allow anyone with access to product catalog to see the category hierarchy, the edit is controlled by another permission
        {
          label: 'Categories',
          to: '/categories',
          hasAccess: hasPermission(operator, Permission.PRODUCT_CATALOG_SEARCH)
        },
        {
          label: 'Custom Offer Rules',
          to: '/sellables/custom-offer-rules',
          hasAccess: hasPermission(operator, Permission.SELLABLE_CUSTOM_OFFER_RULE_READ)
        },
        {
          label: 'Recommended Lists',
          to: '/sellables/recommended-lists',
          hasAccess: hasPermission(operator, Permission.RECOMMENDED_LIST_DEFINITION_READ)
        },
        {
          label: 'Site Navigation',
          to: '/navigation',
          hasAccess: hasPermission(operator, Permission.CATEGORY_NAVIGATION_MANAGE)
        },
        {
          label: 'Sellable Search Page Rules',
          to: '/sellable-search-rules',
          hasAccess: hasPermission(operator, Permission.SELLABLE_SEARCH_RULE_EDIT)
        },
        {
          label: 'Query Mapping Rules',
          to: '/sellables/query-mapping',
          hasAccess: hasPermission(operator, Permission.SELLABLE_SEARCH_REQUEST)
        }
      ]
    },
    {
      label: 'Marketing',
      emoji: '📣',
      menuItems: [
        {
          label: 'Path Builder',
          to: '/paths',
          hasAccess: hasPermission(operator, Permission.PATH_SCHEMA_SEARCH)
        },
        {
          label: 'Triggers',
          to: '/triggers/search',
          hasAccess: hasPermission(operator, Permission.TRIGGER_SEARCH)
        },
        {
          label: 'Campaigns',
          to: '/marketing/campaigns',
          hasAccess: hasPermission(operator, Permission.CAMPAIGN_SEARCH)
        },
        {
          label: 'Email Template Library',
          to: '/email-templates/search',
          hasAccess: hasPermission(operator, Permission.TEMPLATE_MANAGE)
        },
        {
          label: 'Expert Lead Campaign',
          to: '/expert-lead-campaign/search',
          hasAccess: hasPermission(operator, Permission.TEMPLATE_MANAGE)
        },
        {
          label: 'Page Builder',
          to: '/page-templates/search',
          hasAccess: hasPermission(operator, Permission.PAGE_TEMPLATE_READ)
        },
        {
          label: 'Media Asset Library',
          to: '/media-assets',
          hasAccess: hasPermission(operator, Permission.MEDIA_ASSET_READ)
        },
        {
          label: 'System Templates',
          to: '/templates/types',
          hasAccess: hasPermission(operator, Permission.TEMPLATE_MANAGE)
        },
        {
          label: 'Campaign Reports',
          to: '/admin/marketing',
          hasAccess: hasPermission(operator, Permission.EXTERNAL_MARKETING_MANAGE)
        },
        {
          label: 'Audience Builder',
          to: '/marketing/audiences?sortOrder=DESC&searchSort=LAST_UPDATED&searchState=PUBLISHED',
          hasAccess: hasPermission(operator, Permission.CAMPAIGN_AUDIENCE_MANAGE)
        },
        {
          label: 'Promotions',
          to: '/admin/promotions',
          hasAccess: hasPermission(operator, Permission.PROMOTION_SEARCH)
        },
        {
          label: 'Article Inline Path Selection Rule',
          to: '/marketing/content-selection-rules',
          hasAccess: hasPermission(operator, Permission.CONTENT_SELECTION_RULE_SEARCH)
        },
        {
          label: 'Tools',
          to: '/marketing/tools',
          hasAccess: hasPermission(operator, Permission.OPS_TOOLS_URL_SHORTER)
        },
        {
          label: 'Content Selection Rules',
          to: '/content-selection-rules',
          hasAccess: hasPermission(operator, Permission.CONTENT_SELECTION_RULE_MANAGE)
        },
        {
          label: 'SEM Admin',
          to: '/ads-rule',
          hasAccess: hasPermission(operator, Permission.EXTERNAL_AD_CAMPAIGN_CATEGORY_RULE_EDIT)
        },
        {
          label: 'Category Expected Margin',
          to: '/category-target-margins',
          hasAccess: hasPermission(operator, Permission.CATEGORY_TARGET_MARGIN_READ)
        },
        {
          label: 'Template Data Sources',
          to: '/template-data-sources',
          hasAccess: hasPermission(operator, Permission.TEMPLATE_DATA_SOURCE_READ)
        },
        {
          label: 'External Conversion',
          to: '/external-conversions',
          hasAccess: hasPermission(operator, Permission.EXTERNAL_CONVERSION_READ)
        }
      ]
    },
    {
      label: 'Content',
      emoji: '🎥',
      menuItems: [
        {
          label: 'Featured Reviews',
          to: '/content/featured-reviews',
          hasAccess: hasPermission(operator, Permission.CONSUMER_EXPERT_REVIEW_SEARCH)
        },
        {
          label: 'Expert notes',
          to: '/content/expert-notes',
          hasAccess: hasPermission(operator, Permission.EXPERT_CURATED_ITEM_NOTES_MANAGE)
        },
        {
          label: 'Most Recommended Lists',
          to: '/sellables/top-lists',
          hasAccess: hasPermission(operator, Permission.TOP_LIST_MANAGE)
        },
        {
          label: 'Articles',
          to: '/content/articles',
          hasAccess: hasPermission(operator, Permission.ARTICLE_MANAGE)
        },
        {
          label: 'Text Linkify Rules',
          to: '/content/text-linkify-rules',
          hasAccess: hasPermission(operator, Permission.CONTENT_TEXT_LINKIFY_RULE_MANAGE)
        },
        {
          label: 'Gigs',
          to: '/content/gigs/open',
          hasAccess: hasPermission(operator, Permission.GIG_SEARCH)
        },
        {
          label: 'Editorial Top Lists',
          to: '/content/top-lists',
          hasAccess: hasPermission(operator, Permission.SELLABLE_EDITORIAL_TOP_LIST_MANAGE)
        },
        {
          label: 'Editorial Product Reviews',
          to: '/content/product-reviews',
          hasAccess: hasPermission(operator, Permission.SELLABLE_EDITORIAL_REVIEW_READ)
        },
        {
          label: 'Feeds',
          to: '/content/feeds',
          hasAccess: hasPermission(operator, Permission.FEED_MANAGE_SEARCH)
        },
        {
          label: 'Consumer Product Reviews',
          to: '/content/consumer-product-reviews',
          hasAccess: hasPermission(operator, Permission.CONSUMER_EXPERT_REVIEW_SEARCH)
        },
        {
          label: 'Video Content Management',
          to: '/content/videos',
          hasAccess: hasPermission(operator, Permission.VIDEO_CONTENT_READ)
        },
        {
          label: 'Curated List Management',
          to: '/content/curated-lists',
          hasAccess: hasPermission(operator, Permission.INFLUENCER_CURATED_LIST_EDIT)
        },
        {
          label: 'LLM Curated Take Tool',
          to: '/content/llm-curated-takes',
          hasAccess: hasPermission(operator, Permission.SELLABLE_LM_RATING_MANAGE)
        },
        {
          label: 'LLM Curated Take Batch Tool (Sellable LM Rating)',
          to: '/content/llm-curated-take-batch',
          hasAccess: hasPermission(operator, Permission.SELLABLE_LM_RATING_MANAGE)
        },
        {
          label: 'LLM Expert Q&A',
          to: '/content/llm-expert-qna',
          hasAccess: hasPermission(operator, Permission.PRODUCT_QUESTION_AND_ANSWER_MANAGE)
        },
        {
          label: 'LLM Sellable Versus',
          to: '/content/llm-sellable-versus',
          hasAccess: hasPermission(operator, Permission.SELLABLE_VERSUS_CONTENT_MANAGE)
        }
      ]
    },
    {
      label: 'POM',
      emoji: '📦',
      menuItems: [
        {
          label: 'Purchase Orders Management',
          to: '/purchase-orders/purchase-orders-management',
          hasAccess: hasPermission(operator, Permission.PURCHASE_ORDER_READ)
        },
        {
          label: 'Products On Order Overview',
          to: '/purchase-orders/products-on-order-overview',
          hasAccess: hasPermission(operator, Permission.PURCHASE_ORDER_READ)
        },
        {
          label: 'Distribution Center Order Returns',
          to: '/purchase-orders/dc-order-returns',
          hasAccess: hasPermission(operator, Permission.ORDER_READ)
        },
        {
          label: 'Products On Inventory Overview',
          to: '/purchase-orders/products-on-inventory',
          hasAccess: hasPermission(operator, Permission.PURCHASE_ORDER_READ)
        },
        {
          label: 'Outbound Orders Management',
          to: '/purchase-orders/outbound-orders-management',
          hasAccess: hasPermission(operator, Permission.PURCHASE_ORDER_READ)
        },
        {
          label: 'Inventory Adjustments',
          to: '/purchase-orders/inventory-adjustments',
          hasAccess: hasPermission(operator, Permission.PURCHASE_ORDER_READ)
        },
        {
          label: 'Inventory History',
          to: '/purchase-orders/inventory-history',
          hasAccess: hasPermission(operator, Permission.PURCHASE_ORDER_READ)
        },
        {
          label: 'Cost Adjustment Tool',
          to: '/purchase-orders/cost-adjustment-tool',
          hasAccess: hasPermission(operator, Permission.PURCHASE_ORDER_READ)
        },
        {
          label: 'Distribution Centers',
          to: '/purchase-orders/distribution-centers',
          hasAccess: hasPermission(operator, Permission.PURCHASE_ORDER_READ)
        },
        {
          label: 'Inventory Properties',
          to: '/purchase-orders/product-inventory-properties',
          hasAccess: hasPermission(operator, Permission.PURCHASE_ORDER_READ)
        },
        {
          label: 'Payment Terms',
          to: '/purchase-orders/payment-terms',
          hasAccess: hasPermission(operator, Permission.PURCHASE_ORDER_READ)
        },
        {
          label: 'Exceptions',
          to: '/purchase-orders/exceptions',
          hasAccess: hasPermission(operator, Permission.PURCHASE_ORDER_READ)
        },
        {
          label: 'UPS Zone Locators',
          to: '/purchase-orders/ups/ups-zone-locators',
          hasAccess: hasPermission(operator, Permission.PURCHASE_ORDER_READ)
        },
        {
          label: 'UPS Rate Cards',
          to: '/purchase-orders/ups/ups-rate-cards',
          hasAccess: hasPermission(operator, Permission.PURCHASE_ORDER_READ)
        },
        {
          label: 'UPS Surcharges',
          to: '/purchase-orders/ups/ups-surcharges',
          hasAccess: hasPermission(operator, Permission.PURCHASE_ORDER_READ)
        },
        {
          label: 'UPS Shipping Tool',
          to: '/purchase-orders/ups/ups-shipping-tool',
          hasAccess: hasPermission(operator, Permission.PURCHASE_ORDER_READ)
        },
        {
          label: 'FedEx Zone Locators',
          to: '/purchase-orders/fedex-zone-locators',
          hasAccess: hasPermission(operator, Permission.PURCHASE_ORDER_READ)
        },
        {
          label: 'FedEx Rate Cards',
          to: '/purchase-orders/fedex-rate-cards',
          hasAccess: hasPermission(operator, Permission.PURCHASE_ORDER_READ)
        },
        {
          label: 'FedEx Surcharges',
          to: '/purchase-orders/fedex-surcharges',
          hasAccess: hasPermission(operator, Permission.PURCHASE_ORDER_READ)
        },
        {
          label: 'FedEx Shipping Tool',
          to: '/purchase-orders/fedex-shipping-tool',
          hasAccess: hasPermission(operator, Permission.PURCHASE_ORDER_READ)
        },
        {
          label: 'FedEx Shipping Audit',
          to: '/purchase-orders/fedex-bills',
          hasAccess: hasPermission(operator, Permission.PURCHASE_ORDER_READ)
        },
        {
          label: 'Dashboard (WIP)',
          to: '/purchase-orders/dashboard',
          hasAccess: hasPermission(operator, Permission.PURCHASE_ORDER_READ)
        }
      ]
    },
    {
      label: 'General Ledger',
      emoji: '🧾',
      menuItems: [
        {
          label: 'General Ledger Search',
          to: '/general-ledger',
          hasAccess: hasPermission(operator, Permission.GENERAL_LEDGER_SEARCH)
        },
        {
          label: 'Upload Adjustments',
          to: '/general-ledger/adjustments/upload',
          hasAccess: hasPermission(operator, Permission.GENERAL_LEDGER_ADJUSTMENT)
        }
      ]
    },
    {
      label: 'Admin',
      emoji: '🥷',
      menuItems: [
        {
          label: 'Experiments',
          to: '/experiments',
          hasAccess: hasPermission(operator, Permission.EXPERIMENT_SEARCH)
        },
        {
          label: 'Attributes',
          to: '/attributes',
          hasAccess: hasPermission(operator, Permission.ATTRIBUTE_SEARCH)
        },
        {
          label: 'Configurations',
          to: '/admin/configurations',
          hasAccess: hasPermission(operator, Permission.CONFIG_MANAGE)
        },
        {
          label: 'Operators',
          to: '/admin/operators',
          hasAccess: hasPermission(operator, Permission.OPERATOR_SEARCH)
        },
        // {
        //   label: 'Tenants',
        //   to: '/admin/tenants',
        //   hasAccess: hasPermission(operator, Permission.TENANT_EDIT)
        // },
        // {
        //   label: 'Publishers',
        //   to: '/admin/publishers',
        //   hasAccess: hasPermission(operator, Permission.PUBLISHER_EDIT)
        // },
        {
          label: 'Devtools',
          to: '/devtools',
          hasAccess: hasPermission(operator, Permission.OPS_TOOL_ID_CONVERTER)
        },
        {
          label: 'LLM Prompts',
          to: `/llm/prompts`,
          hasAccess: hasPermission(operator, Permission.PROMPT_TEMPLATE_REVISION_READ)
        },
        {
          label: 'LLM Prompt Testing',
          to: `/llm/prompt-testing`,
          hasAccess: hasPermission(operator, Permission.PROMPT_TEMPLATE_REVISION_READ)
        }
      ]
    }
  ]
}

export const useNavigationMenus = () => {
  const allNavMenus = useAllNavigationMenus()

  // check to make sure each menu has either menu items or an ops url path, but not both
  if (
    allNavMenus.some(
      menu => (!menu?.menuItems?.length && !menu?.to) || (menu?.menuItems?.length && menu?.to)
    )
  ) {
    throw new Error('Menu requires either menu items or an ops url path, but not both')
  }

  // filter menu items by permissions
  const filteredNavMenus = allNavMenus.map(menu => {
    if (menu.menuItems) {
      menu.menuItems = menu.menuItems.filter(({ hasAccess }) => hasAccess)
    }
    return menu
  })

  // filter out any empty menus
  return filteredNavMenus.filter(menu => menu?.menuItems?.length || menu?.to)
}

export const useRecentNavigationMenuItems = () => {
  const [recentNavigationMenuItems, setRecentNavigationMenuItems] = useState<MenuItem[]>([])

  // Hydrate the state on mount
  useEffect(() => {
    setRecentNavigationMenuItems(navigationMenuItemsStore.get('recent') || [])
  }, [])

  const addRecentNavigationItem = (item: MenuItem) => {
    // Fetch the latest state from local storage since the local React state may be out of date
    const recentNavigationMenuItemsFromStore = (navigationMenuItemsStore.get('recent') ||
      []) as MenuItem[]

    // Move the new item to the front of the list, and remove any duplicates
    const updatedRecentNavigationMenuItems: MenuItem[] = [
      item,
      ...recentNavigationMenuItemsFromStore.filter(recentItem => recentItem.to !== item.to)
    ].slice(0, 5)

    // Update local storage
    navigationMenuItemsStore.set('recent', updatedRecentNavigationMenuItems)

    // Update React state
    setRecentNavigationMenuItems(updatedRecentNavigationMenuItems)
  }

  return {
    recentNavigationMenuItems,
    addRecentNavigationItem
  }
}

export const useFavoriteNavigationMenuItems = () => {
  const [favoriteNavigationMenuItems, setFavoriteNavigationMenuItems] = useState<MenuItem[]>([])

  // Hydrate the state on mount
  useEffect(() => {
    setFavoriteNavigationMenuItems(navigationMenuItemsStore.get('favorites') || [])
  }, [])

  const addFavoriteNavigationItem = (item: MenuItem) => {
    // Fetch the latest state from local storage since the local React state may be out of date
    const favoriteNavigationMenuItems = (navigationMenuItemsStore.get('favorites') ||
      []) as MenuItem[]

    // Add the favorited item to the list, and remove any duplicates
    const updatedRecentNavigationMenuItems: MenuItem[] = [
      item,
      ...favoriteNavigationMenuItems.filter(favoriteItem => favoriteItem.to !== item.to)
    ]

    // Update local storage
    navigationMenuItemsStore.set('favorites', updatedRecentNavigationMenuItems)

    // Update React state
    setFavoriteNavigationMenuItems(updatedRecentNavigationMenuItems)
  }

  const removeFavoriteNavigationItem = (item: MenuItem) => {
    // Fetch the latest state from local storage since the local React state may be out of date
    const favoriteNavigationMenuItems = (navigationMenuItemsStore.get('favorites') ||
      []) as MenuItem[]

    // Remove the item from the favorites
    const updatedRecentNavigationMenuItems: MenuItem[] = favoriteNavigationMenuItems.filter(
      favoriteItem => favoriteItem.to !== item.to
    )

    // Update local storage
    navigationMenuItemsStore.set('favorites', updatedRecentNavigationMenuItems)

    // Update React state
    setFavoriteNavigationMenuItems(updatedRecentNavigationMenuItems)
  }

  return {
    favoriteNavigationMenuItems,
    addFavoriteNavigationItem,
    removeFavoriteNavigationItem
  }
}
