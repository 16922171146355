import React from 'react'
import loadable from '@loadable/component'
import { FullPageLoader } from '@deal/components'

export const Admin = loadable(() => import(/* webpackChunkName: "admin" */ './admin'), {
  fallback: <FullPageLoader />
})
export const Assistants = loadable(
  () => import(/* webpackChunkName: "assistants" */ './assistants'),
  { fallback: <FullPageLoader /> }
)
export const Attributes = loadable(
  () => import(/* webpackChunkName: "attributes" */ './attributes'),
  { fallback: <FullPageLoader /> }
)
export const Brands = loadable(() => import(/* webpackChunkName: "brands" */ './brands'), {
  fallback: <FullPageLoader />
})
export const CallCenter = loadable(
  () => import(/* webpackChunkName: "call-center" */ './call-center'),
  { fallback: <FullPageLoader /> }
)
export const Categories = loadable(
  () => import(/* webpackChunkName: "categories" */ './categories'),
  {
    fallback: <FullPageLoader />
  }
)
export const VirtualCategoriesTree = loadable(
  () => import(/* webpackChunkName: "virtual-categories-tree" */ './virtual-categories-tree'),
  {
    fallback: <FullPageLoader />
  }
)
export const Consumers = loadable(() => import(/* webpackChunkName: "consumers" */ './consumers'), {
  fallback: <FullPageLoader />
})
export const Content = loadable(() => import(/* webpackChunkName: "content" */ './content'), {
  fallback: <FullPageLoader />
})
export const DevTools = loadable(() => import(/* webpackChunkName: "devtools" */ './devtools'), {
  fallback: <FullPageLoader />
})
export const Experiments = loadable(
  () => import(/* webpackChunkName: "experiments" */ './experiments'),
  { fallback: <FullPageLoader /> }
)
export const Experts = loadable(() => import(/* webpackChunkName: "experts" */ './experts'), {
  fallback: <FullPageLoader />
})
export const Home = loadable(() => import(/* webpackChunkName: "home" */ './home'), {
  fallback: <FullPageLoader />
})
export const Inventory = loadable(() => import(/* webpackChunkName: "inventory" */ './inventory'), {
  fallback: <FullPageLoader />
})
export const Leads = loadable(() => import(/* webpackChunkName: "leads" */ './leads'), {
  fallback: <FullPageLoader />
})
export const Marketing = loadable(() => import(/* webpackChunkName: "marketing" */ './marketing'), {
  fallback: <FullPageLoader />
})
export const MediaAssets = loadable(
  () => import(/* webpackChunkName: "media-assets" */ './media-assets'),
  {
    fallback: <FullPageLoader />
  }
)
export const Merchants = loadable(() => import(/* webpackChunkName: "merchants" */ './merchants'), {
  fallback: <FullPageLoader />
})
export const Navigation = loadable(
  () => import(/* webpackChunkName: "navigation" */ './navigation'),
  { fallback: <FullPageLoader /> }
)
export const Orders = loadable(() => import(/* webpackChunkName: "orders" */ './orders'), {
  fallback: <FullPageLoader />
})
export const Partners = loadable(() => import(/* webpackChunkName: "partners" */ './partners'), {
  fallback: <FullPageLoader />
})
export const Payroll = loadable(() => import(/* webpackChunkName: "payroll" */ './payroll'), {
  fallback: <FullPageLoader />
})
export const Scheduling = loadable(
  () => import(/* webpackChunkName: "scheduling" */ './scheduling'),
  { fallback: <FullPageLoader /> }
)
export const Sellables = loadable(() => import(/* webpackChunkName: "sellables" */ './sellables'), {
  fallback: <FullPageLoader />
})
export const Templates = loadable(() => import(/* webpackChunkName: "templates" */ './templates'), {
  fallback: <FullPageLoader />
})
export const Triggers = loadable(() => import(/* webpackChunkName: "triggers" */ './triggers'), {
  fallback: <FullPageLoader />
})
export const Path = loadable(() => import(/* webpackChunkName: "path" */ './path'), {
  fallback: <FullPageLoader />
})
export const ProductCatalog = loadable(
  () => import(/* webpackChunkName: "product-catalog" */ './product-catalog'),
  { fallback: <FullPageLoader /> }
)
export const EmailTemplate = loadable(
  () => import(/* webpackChunkName: "email-templates" */ './email-templates'),
  { fallback: <FullPageLoader /> }
)
export const ExpertLeadCampaign = loadable(
  () => import(/* webpackChunkName: "expert-lead-campaign" */ './expert-lead-campaign'),
  { fallback: <FullPageLoader /> }
)
export const ContentSelectionRules = loadable(
  () => import(/* webpackChunkName: "content-selection-rules" */ './content-selection-rules'),
  { fallback: <FullPageLoader /> }
)
export const ContentSelection = loadable(
  () => import(/* webpackChunkName: "content-selection" */ './content-selection'),
  { fallback: <FullPageLoader /> }
)
export const AdsRule = loadable(() => import(/* webpackChunkName: "ads-rule" */ './ads-rule'), {
  fallback: <FullPageLoader />
})
export const SellableSearchRules = loadable(
  () => import(/* webpackChunkName: "sellable-search-rules" */ './sellable-search-rules'),
  {
    fallback: <FullPageLoader />
  }
)
export const PurchaseOrder = loadable(
  () => import(/* webpackChunkName: "purchase-order" */ './purchase-order'),
  { fallback: <FullPageLoader /> }
)
export const PageTemplates = loadable(
  () => import(/* webpackChunkName: "page-templates" */ './page-templates'),
  { fallback: <FullPageLoader /> }
)
export const Standardization = loadable(
  () => import(/* webpackChunkName: "standardization" */ './standardization'),
  { fallback: <FullPageLoader /> }
)
export const Affiliate = loadable(
  () => import(/* webpackChunkName: "Affiliates" */ './affiliates'),
  { fallback: <FullPageLoader /> }
)
export const AffiliateCampaignsManagement = loadable(
  () =>
    import(
      /* webpackChunkName: "affiliate-campaigns-management" */ './affiliate-campaigns-management'
    ),
  { fallback: <FullPageLoader /> }
)
export const CategoryTargetMargins = loadable(
  () => import(/* webpackChunkName: "category-target-margins" */ './category-target-margins'),
  { fallback: <FullPageLoader /> }
)
export const TemplateDataSources = loadable(
  () => import(/* webpackChunkName: "template-data-sources" */ './template-data-sources'),
  { fallback: <FullPageLoader /> }
)
export const GeneralLedger = loadable(
  () => import(/* webpackChunkName: "general-ledger" */ './general-ledger'),
  { fallback: <FullPageLoader /> }
)

export const ExternalConversions = loadable(
  () => import(/* webpackChunkName: "external-conversions" */ './external-conversions'),
  { fallback: <FullPageLoader /> }
)

export const VideoContentManagement = loadable(
  () => import(/* webpackChunkName: "video-content-management" */ './video-content-management'),
  { fallback: <FullPageLoader /> }
)

export const CuratedListsPage = loadable(
  () => import(/* webpackChunkName: "curated-lists" */ './content/CuratedLists'),
  { fallback: <FullPageLoader /> }
)

export const LargeLanguageModel = loadable(() => import(/* webpackChunkName: "llm" */ './llm'), {
  fallback: <FullPageLoader />
})
